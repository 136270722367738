import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'

const CreatePassword = ({ handleSubmit }) => {
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)

  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }
  return (
    <>
      <div className="forgot-sub-container">
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          Forgot Password
        </div>
        <div>
          Enter your email address associated with your account and we’ll send
          you a OTP to reset your password
        </div>
        <div className="sub-container">
          <div htmlFor="password">New Password</div>
          <TextField
            placeholder="Enter your password"
            type={passwordVisible ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="text-field"
          />
        </div>
        <div className="sub-container">
          <div htmlFor="password">Confirm Password</div>
          <TextField
            placeholder="Enter password again to confirm"
            type={passwordVisible ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {confirmPasswordVisible ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className="text-field"
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
      >
        Reset Password
      </Button>
    </>
  )
}

export default CreatePassword
