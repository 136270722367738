import React, { useEffect, useState } from 'react'
import '../Login/login.css'
import {
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import useRegisterUserStore from '../../../store/registerUser'

const RegistrationForm = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    fullName: '',
    gender: 'Male',
    dob: dayjs(format(new Date(), 'yyyy-MM-dd')),
    password: '',
    email: '',
    address: '',
    mobileNumber: '',
    referralName: '',
  })
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [error, setError] = useState({})
  const requiredKeys = ['password', 'email', 'confirmPassword']

  const { userData, addUserData } = useRegisterUserStore()

  const handleChange = (e) => {
    setError({})
    const { name, value, type } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDateChange = (newValue) => {
    setError({})
    setFormData((prev) => ({
      ...prev,
      dob: newValue,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let errorFields = {}
    Object.keys(formData).forEach((data) => {
      if (requiredKeys?.includes(data) && formData[data]?.length <= 0) {
        errorFields[data] = true
      }
    })
    if (formData?.password !== confirmPassword) {
      errorFields['confirmPassword'] = true
      setError(errorFields)
      return
    }
    if (!Object.keys(errorFields)?.length) {
      addUserData({
        ...formData,
        dob: format(new Date(formData?.dob.toDate()), 'yyyy-MM-dd'),
      })
      navigate('/retail/packages')
    } else {
      setError(errorFields)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  useEffect(() => {
    setFormData({
      ...userData,
      dob: dayjs(format(new Date(userData?.dob), 'yyyy-MM-dd')),
    })
  }, [])

  return (
    <div className="login-container">
      <div className="sub-container">
        <div>Full Name</div>
        <TextField
          variant="outlined"
          fullWidth
          name="fullName"
          placeholder="Enter your full name"
          value={formData.fullName}
          onChange={handleChange}
        />
      </div>
      <div className="sub-container">
        <div className="grid-container">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Gender</div>
            <Select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
            >
              <MenuItem
                value="Male"
                style={{ color: 'black', background: '# ' }}
              >
                Male
              </MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Date of Birth</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker value={formData.dob} onChange={handleDateChange} />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="sub-container">
        <div>Address</div>
        <TextField
          variant="outlined"
          fullWidth
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </div>
      <div className="sub-container">
        <div className="grid-container">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Mobile Number</div>
            <TextField
              variant="outlined"
              fullWidth
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Email</div>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              helperText={
                error.email && (
                  <div
                    style={{ marginTop: '8px', color: 'red', fontSize: '12px' }}
                  >
                    This is a required field
                  </div>
                )
              }
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="sub-container">
        <div>Referral Name</div>
        <TextField
          variant="outlined"
          fullWidth
          name="referralName"
          value={formData.referralName}
          onChange={handleChange}
        />
      </div>
      <div className="sub-container">
        <div className="grid-container">
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Create your Password</div>
            <TextField
              variant="outlined"
              fullWidth
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={formData.password}
              helperText={
                error.password && (
                  <div
                    style={{ marginTop: '8px', color: 'red', fontSize: '12px' }}
                  >
                    This is a required field
                  </div>
                )
              }
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
          >
            <div>Confirm Your Password</div>
            <TextField
              variant="outlined"
              fullWidth
              type={passwordVisible ? 'text' : 'password'}
              name="confirmPassword"
              value={confirmPassword}
              helperText={
                error.confirmPassword && (
                  <div
                    style={{ marginTop: '8px', color: 'red', fontSize: '12px' }}
                  >
                    Does not match with password
                  </div>
                )
              }
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            name="termsAccepted"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
        }
        label={
          <div>
            By signing up, I agree to{' '}
            <span style={{ color: '#FFE500' }}>Terms of Use</span> and{' '}
            <span style={{ color: '#FFE500' }}>Privacy Policy.</span>
          </div>
        }
      />
      <Button
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
        className="submit-button"
        disabled={!termsAccepted}
      >
        Select Package
      </Button>
    </div>
  )
}

export default RegistrationForm
