import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../components/Navbar'
import Bg from '../resources/images/Bg.png'
import Image from '../resources/images/Tour_Image_Alt.png'
import Ellipse from '../resources/images/Ellipse15.png'
import { Link } from 'react-router-dom'
import { ReactComponent as ArrowIcon } from '../resources/images/Arrow_Icon_Black.svg'
import ComingSoonImage from '../resources/images/Coming_Soon_Image.svg'

const ToursAndTravelism = () => {
  const theme = useTheme()
  return (
    <>
      <Helmet>
        <title>{`Tourism | ${process.env.REACT_APP_APP_NAME}`}</title>
      </Helmet>
      <NavBar />

      <Container maxWidth="xl">
        <Box
          sx={{
            // marginTop: { xs: '25px', md: '30px' },
            minHeight: '516px',
            '::after': {
              content: "' '",
              backgroundImage: `url(${Bg})`,
              backgroundPosition: 'top center',
              backgroundRepeat: 'no-repeat',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              zIndex: -1,
            },
          }}
          position="relative"
        >
          <Box position="absolute" right={0} top={300}>
            <img src={Ellipse} alt="" />
          </Box>
          <Typography
            variant="xss"
            letterSpacing="0.7px"
            textAlign="center"
            display="block"
            maxWidth="1307px"
            margin="auto"
            mt="5px"
          >
            Tourism
          </Typography>
          <Typography
            lineHeight={1.36}
            variant="h4"
            mt="10px"
            letterSpacing="0.44px"
            fontWeight={400}
            textAlign="center"
            display="block"
            maxWidth="1142px"
            mx="auto"
            sx={{ opacity: 0.8 }}
            px="20px"
          >
            One year into our Polarize Network journey, Scholars Adda has been
            the cornerstone, imparting value to over 3000 users as the gateway
            to our ecosystem.{' '}
          </Typography>
          <Grid
            container
            pl={{ xs: '30px', sm: '87px', md: '203px' }}
            pr={{ xs: '30px', sm: '87px' }}
            mt="50px"
            pb="100px"
            columnSpacing="73px"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                maxWidth="527px"
              >
                Polarize Tourism: Explore with PLR Tokens
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Embark on a unique travel experience with Polarize Tourism, where every journey is enhanced by the power of PLR
                Tokens. This innovative platform within the Polarize Network
                ecosystem seamlessly integrates cryptocurrency into the world of
                travel, allowing users to book flights, accommodations, and
                unforgettable experiences using PLR Tokens. Unlock special perks
                and exclusive deals as you explore the world, bridging the gap
                between traditional travel and the decentralized Web3 landscape.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Polarize Tourism goes beyond conventional travel
                platforms by incorporating blockchain technology and the PLR
                Token. By doing so, we not only provide a secure and transparent
                booking process but also reward users with additional benefits
                for utilizing PLR Tokens. As you plan your next adventure,
                experience the convenience, savings, and sophistication that
                come with cryptocurrency-powered travel.
              </Typography>
              <Typography
                variant="h7"
                fontWeight={400}
                lineHeight={1.95}
                letterSpacing="0.4px"
                sx={{ opacity: 0.6 }}
                display="block"
                mt="15px"
                maxWidth="527px"
              >
                Become a pioneer in the intersection of travel and decentralized
                finance, where PLR Tokens open doors to a new era of
                exploration. Join us in redefining how individuals navigate the
                globe, where every trip becomes an opportunity to connect with
                the world while being part of a thriving digital ecosystem.
                Polarize Tourism invites you to discover the future of
                travel, where the possibilities are as boundless as your
                imagination.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} pt="163px">
              <img alt="" src={Image} />
              {/* <Button
                variant="contained"
                sx={{
                  padding: '20px 22px',
                  borderRadius: '34px',
                  color: theme.palette.common.black,
                  fontFamily: 'Krona One',
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: '0.56px',
                  maxWidth: '294px',
                  width: '100%',
                  lineHeight: 1,
                  maxHeight: '57px',
                  mx: 'auto',
                  display: 'flex',
                  mt: '155px',
                }}
                LinkComponent={Link}
                // to="/tokenomics"
              >
                Coming Soon <ArrowIcon height="32px" width="32px" />
              </Button> */}

              <Box>
                <img src={ComingSoonImage} alt="" style={{ margin: 'auto' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default ToursAndTravelism
