import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { retry } from './common/utils/commonFunction'
import ToursAndTravelism from './pages/ToursAndTravelism'
import PLRExchnage from './pages/PLRExchnage'
import ScholarsAdda from './pages/ScholarsAdda'
import PLRMart from './pages/PLRMart'
import PreSale from './pages/PreSale'
import Retail from './pages/Retail'
import Packages from './pages/Packages'

const Home = lazy(() => retry(() => import('./pages/Home')))
const Carrers = lazy(() => retry(() => import('./pages/Carrers')))
const AboutUs = lazy(() => retry(() => import('./pages/AboutUs')))
const Tokenomics = lazy(() => retry(() => import('./pages/Tokenomics')))
const ContactUs = lazy(() => retry(() => import('./pages/ContactUs')))
const AccessPlatform = lazy(() => retry(() => import('./pages/AccessPlatform')))
const PreSeed = lazy(() => retry(() => import('./pages/PreSeed')))
const HowItWorks = lazy(() => retry(() => import('./pages/HowItWorks')))
const Blogs = lazy(() => retry(() => import('./pages/Blogs')))
const Blog = lazy(() => retry(() => import('./pages/Blog')))
const FourZeroFour = lazy(() => retry(() => import('./pages/FourZeroFour')))
const TermsAndConditions = lazy(() =>
  retry(() => import('./pages/TermsAndConditions')),
)
const PrivacyPolicy = lazy(() => retry(() => import('./pages/PrivacyPolicy')))

const Routes = () => {
  const route = useRoutes([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/careers',
      element: <Carrers />,
    },
    {
      path: '/presale',
      element: <PreSale />,
    },
    {
      path: '/about-us',
      element: <AboutUs />,
    },
    {
      path: '/tourism',
      element: <ToursAndTravelism />,
    },
    {
      path: '/plr-exchange',
      element: <PLRExchnage />,
    },
    {
      path: '/scholars-adda',
      element: <ScholarsAdda />,
    },
    {
      path: '/plr-mart',
      element: <PLRMart />,
    },
    {
      path: '/tokenomics',
      element: <Tokenomics />,
    },
    {
      path: '/contact-us',
      element: <ContactUs />,
    },
    {
      path: '/access-platform',
      element: <AccessPlatform />,
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/pre-seed',
      element: <PreSeed />,
    },
    {
      path: '/how-it-works',
      element: <HowItWorks />,
    },
    {
      path: '/blogs',
      element: <Blogs />,
    },
    {
      path: '/blog',
      element: <Blog />,
    },
    {
      path: '/retail/:params',
      element: <Retail forgotPassword={false} />,
    },
    {
      path: '/retail/forgot-password',
      element: <Retail forgotPassword={true} />,
    },
    {
      path: '/retail/packages',
      element: <Packages />,
    },
    {
      path: '/*',
      element: <Navigate replace to="/404" />,
    },
    {
      path: '/404',
      element: <FourZeroFour />,
    },
  ])
  return route
}

export default Routes
