import { TextField } from '@mui/material'
import { useState } from 'react'
import CodeIcon from '../../../../resources/images/Frame 40101.png'
import useRegisterUserStore from '../../../../store/registerUser'

const BankTransfer = () => {
  const { bankDetails, addBankDetails } = useRegisterUserStore()
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          padding: '40px',
        }}
      >
        <img src={CodeIcon} alt="code" />
        <small>UPI ID : 6354947978@ybl</small>
      </div>
      <div
        style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Bank Name</div>
          <TextField
            type="text"
            placeholder="Enter your Bank Name"
            fullWidth
            variant="outlined"
            value={bankDetails?.bankName}
            onChange={(e) =>
              addBankDetails((prev) => ({ ...prev, bankName: e.target.value }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Bank Holder Name</div>
          <TextField
            type="text"
            placeholder="Enter your Bank Holder Name"
            fullWidth
            variant="outlined"
            value={bankDetails?.bankHolderName}
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                bankHolderName: e.target.value,
              }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>Account Number</div>
          <TextField
            type="text"
            placeholder="Enter your Account Number"
            fullWidth
            variant="outlined"
            value={bankDetails?.accountNumber}
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                accountNumber: e.target.value,
              }))
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <div>IFSC Code</div>
          <TextField
            type="text"
            placeholder="Enter your IFSC Code"
            fullWidth
            variant="outlined"
            value={bankDetails?.ifsc}
            onChange={(e) =>
              addBankDetails((prev) => ({
                ...prev,
                ifsc: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </div>
  )
}

export default BankTransfer
