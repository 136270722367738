import { Button, TextField } from '@mui/material'
import { useState } from 'react'

const VerifyEmail = ({ handleSubmit }) => {
  const [email, setEmail] = useState('')

  return (
    <>
      <div className="forgot-sub-container">
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          Forgot Password
        </div>
        <div>
          Enter your email address associated with your account and we’ll send
          you a OTP to reset your password
        </div>
        <div className="sub-container">
          <div>Email ID</div>
          <TextField
            type="email"
            placeholder="Enter your email address"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="text-field"
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        variant="contained"
        fullWidth
        style={{ borderRadius: 50, minHeight: '48px', color: '#000' }}
      >
        Recieve OTP
      </Button>
    </>
  )
}

export default VerifyEmail
